import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'
import KlarnaPlacement from '@components/common/KlarnaPlacement'

import { getStore } from '@utils/countries'
import { ApplePay, Paypal } from '@components/icons'

import s from './FooterPaymentsMethods.module.css'

const FooterPaymentsMethods: FC<{}> = () => {
  const { locale } = useRouter()
  const { deliveryCountryCode } = useUserSettings()

  const store = getStore({ locale, deliveryCountryCode })

  return (
    <div className={s.container}>
      <KlarnaPlacement
        placementValue={{ key: 'footer-promotion-auto-size', locale: store }}
      />
      <div className={s.logosContainer}>
        <ApplePay width={49} color={'#FFFFFF'} className={s.paymentsLogo} />
        <Paypal width={80} className={s.paymentsLogo} />
      </div>
    </div>
  )
}

export default FooterPaymentsMethods
